import classNames from 'classnames';
import * as React from 'react';
import { Headline } from './headline';

import screenshotOne from "../../assets/speeddial/screenshot_01.webp";
import screenshotThree from "../../assets/speeddial/screenshot_03.webp";
import jackSplash from "../../assets/speeddial/jack_splash.webp";
import mariaSplash from "../../assets/speeddial/maria_splash.webp";
import bankThumbnail from "../../assets/speeddial/sd_bank.webp";
import lairThumbnail from "../../assets/speeddial/sd_lair.webp";
import clubThumbnail from "../../assets/speeddial/sd_club.webp";

import viewStyles from '../view.module.scss';
import { Paragraph } from '../../components/paragraph';
import styles from './index.module.scss';
import { Gallery } from '../../components/gallery';
import galleryResource from "../../assets/speeddial/gallery/manifest";
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';

export default class SpeeddialProjectView extends React.Component {
  componentDidMount(): void {
    firebase.analytics().logEvent('page_speeddialproject');
  }

  render(): JSX.Element {
    return <div className={viewStyles.view}>
      <Headline />
      <Link to="/speeddial/scoreboard">
        <div className={styles["most-wanted"]}>
          <div className={classNames(styles.link, styles["most-wanted-button"])}>most wanted list</div>
        </div>
      </Link>
      <div className={classNames(styles.preview, styles.thumbnail)}>
        <div className={styles.title}>Gameplay Footage</div>
        <div className={styles.videoWrapper}>
          <iframe width="648" height="512" src="https://www.youtube.com/embed/HQ5XF7X28tE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      <Paragraph className={styles.retro}>
        "HIGH-OCTANE, THE FREE MARKET AT WORK--CHRISTIAN FAMILY FUN." <br />- Ronald Reagan<br /><br /><br /><br />
        "Has the potential of poisoning the minds of an entire generation." <br />- Caligula<br /><br /><br /><br />
        "There is no way in HELL we're putting our name on this culturally blastomphous, hippie drug gun game." <br />- Our publishers
      </Paragraph>
      <div className={styles.preview}>
        <img src={screenshotOne}></img>
      </div>
      <Paragraph className={styles.retro}>
        You better be ready for what's coming kid!
        <br /><br />
        So grab your guns, take your drugs--cus' we're about to live out the American dream!
        <img src={jackSplash}></img>
      </Paragraph>
      <div className={styles.preview}>
        <img src={screenshotThree}></img>
      </div>
      <Paragraph className={styles.retro}>
        [...]
        <img src={mariaSplash}></img>
      </Paragraph>
      <div className={styles.mapList}>
        <div className={styles.title}>Map List</div>
        <div className={styles.mapListContainer}>
          <img src={bankThumbnail}></img>
          <img src={lairThumbnail}></img>
          <img src={clubThumbnail}></img>
        </div>
      </div>
      <div className={styles.title}>Content Gallery</div>
      <Gallery resources={galleryResource}></Gallery>
    </div>
  }
}
