import classNames from "classnames";
import * as React from "react";
import { render } from "react-dom";

import background from "../../assets/launch_video.webm";
import styles from './video_background.module.scss';

interface VideoProps {
  className: any;
}

interface VideoState {
  fade: boolean
}

export class VideoBackground extends React.Component<VideoProps, VideoState> {
  containerRef_: React.RefObject<HTMLDivElement>;
  onScroll_ : () => void;

  constructor(props: VideoProps) {
    super(props);
    this.state = {fade: false};
    this.containerRef_ = React.createRef();
  }

  componentDidMount(): void {
      const videoHeight = this.containerRef_.current.getBoundingClientRect().height
      this.onScroll_ = () => {
        const shouldFade = videoHeight/window.devicePixelRatio - window.scrollY - 25/(Math.max(0.5, window.devicePixelRatio)) < 0;
        this.setState({fade: shouldFade})
      }
      window.addEventListener('scroll', this.onScroll_)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll_)
  }

  render(): JSX.Element {
    const classes = classNames(this.props.className, styles["video-container"], {[styles.fade]: this.state.fade})
    return (
      <div ref={this.containerRef_} className={classes}>
        <video autoPlay muted loop>
          <source src={background} type="video/webm" />
        </video>
      </div>
    );
  }
}