//https://us-central1-eagle-one-web.cloudfunctions.net/speeddial-scoreboard

import * as React from 'react';
import firebase from 'firebase/app';
import bent from 'bent';
import classNames from 'classnames';

import Loader from "react-loader-spinner";

import styles from './index.module.scss';
import viewStyles from '../view.module.scss';
import { Link, useParams } from 'react-router-dom';

interface Player {
  avatar: string;
  name: string;
  time: number;
  steam: string;
}

interface ScoreboardState {
  players: Player[]
}

interface ScoreboardProp {
  mapId: string
}

export default class GrandprixScoreboardView extends React.Component<ScoreboardProp, ScoreboardState> {
  
  constructor(props: ScoreboardProp) {
    super(props);
    
    this.state = {players: [] };
  }
  
  componentDidMount(): void {
    this.fetchScoreboard();
  }

  componentDidUpdate(prevProps: ScoreboardProp): void {
    if (this.props.mapId !== prevProps.mapId) {   
        this.setState({players: []});
        this.fetchScoreboard();
    }
  }

  fetchScoreboard(): void {
    firebase.analytics().logEvent('page_grandprix_scoreboard');

    const getMap = bent('json', 'GET', 'json', 200);
    getMap(
      `https://us-central1-eagle-one-web.cloudfunctions.net/grandprix-scoreboard?mapId=${this.props.mapId}`, 
    ).then((players: Player[]) => {
      this.setState({players});
    });
  }

  render(): JSX.Element {
    return <div className={classNames(viewStyles.view, styles.view)}>
      <div className={styles.scoreboard}>
        <div  className={styles["map-container"]}>
          <Link to="/projects/grandprix">
            <div className={styles.scoreboardlink}>
              Back
            </div>
          </Link>
          <Link to="scoreboard?mapId=eagleone.gp_neotokyo">
            <div className={classNames(styles["map-select"], (this.props.mapId == "eagleone.gp_neotokyo") ? styles.selected : "")}>Neotokyo</div>
          </Link>
          <Link to="scoreboard?mapId=eagleone.gp_cyprus">
            <div className={classNames(styles["map-select"], (this.props.mapId == "eagleone.gp_cyprus") ? styles.selected : "")}>Cyprus</div>
          </Link>
        </div>
        <div className={styles.container}>
          <div className={styles.title}>Map Scoreboard</div>
          <div className={styles["map-title"]}>{this.props.mapId}</div>
          <div className={styles.header}>
            Lap Time
          </div>
          {this.state.players.length == 0 ?       
          <div className={styles.loader}>
            <Loader
              type="Rings"
              color="black"
              height={100}
              width={100}
              timeout={3000}
            /> 
          </div>
          : ''}
          {this.state.players.sort((a, b) => a.time - b.time).map(this.playerTemplate)}
        </div>
      </div>
    </div>;
  }

  playerTemplate(player: Player, index: number): JSX.Element {
    const classes = classNames({
      [styles.player]: true,
      [styles.champ]: index == 0,
      [styles.top]: index >= 0 && index < 1
    });

    if (Number.isSafeInteger(player.steam)) {
      return <div></div>;
    }

    return <div key={index} className={classes}>
      <div className={styles.position}>{index+1}</div>
      <img className={styles.avatar} src={player.avatar} />
      <a href={"https://steamcommunity.com/profiles/" + player.steam} className={styles.name} target="_blank" rel="noopener noreferrer">
        {player.name}
      </a>
      <div className={styles.time}>
        {toHHMMSS(player.time)}
      </div>
    </div>
  }
}

function toHHMMSS(time: number) {
  const date = new Date(0);
  date.setSeconds(time, (time - Math.floor(time)) * 1000);
  const format = date.toISOString().substr(11, 12);

  return format;
}