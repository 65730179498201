import classNames from 'classnames';
import firebase from 'firebase';
import * as React from 'react';
import Projects from '../components/projects';

import styles from './projects.module.scss';

export default class ProjectsView extends React.Component {
  componentDidMount(): void {
    firebase.analytics().logEvent('page_projects');
  }

  render(): JSX.Element {
    return (
      <div className={classNames("container", styles.projects)}>
        <div className={styles["projects-title"]}>Projects</div>
        <Projects></Projects>
      </div>
    );
  }
}
