import classNames from 'classnames';
import * as React from 'react';

import firebase from 'firebase/app';

import viewStyles from './view.module.scss';


export default class AboutView extends React.Component {

  componentDidMount(): void {
    firebase.analytics().logEvent('page_about');
  }
  
  render(): JSX.Element {
    return <div className={viewStyles.view}>
        <div style={{paddingTop: "128px", textAlign: "center", fontSize: "2em"}} className={classNames(viewStyles.content)}>
        Work in Progress.
      </div> 
    </div>;
  }
}
