import * as React from "react";
import speeddialLogo from "../../assets/speeddial/logo.webp";

import styles from "./headline.module.scss";

export const Headline: () => JSX.Element = () => {
  return (
    <div className={styles.loadingGradient}>
      <div className={styles.headline}>
        <div className={styles.group}>
        <img src={speeddialLogo} className={styles.title}></img>
          <div className={styles["demo-link"]}>
            RELEA$ED FOR $ANDBOX
          </div>
        </div>
      </div>
      </div>
  );
}