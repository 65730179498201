import * as React from "react";

import { Link } from "react-router-dom";

import Twitter from "../assets/logos/twitter_white.svg";
import Discord from "../assets/logos/discord_white.svg";

import styles from './app_navigation.module.scss';
import lgbtIcon from "../assets/logos/eagleone_lgbt.webp";

export class AppNavigation extends React.Component {

  componentDidMount(): void {
    // Preload lgbt icon before hover.
    const img = new Image();
    img.src = lgbtIcon;
  }

  render(): JSX.Element {
    return <div className={styles.navbar}>
      <Link to="/" title="EagleOne.dev">
        <div className={styles.logo}></div>
      </Link>
      <div className={styles.seperator}></div>
      <div className={styles.group}>
        <Link to="/projects">
          <div className={styles.link}>projects</div>
        </Link>
        <Link to="/team">
          <div className={styles.link}>team</div>
        </Link>
        <a rel="noreferrer" target="_blank" href="https://twitter.com/EagleOneDevs">
          <div className={styles.icon}>
            <img className={styles.link} src={Twitter} title="Our Twitter" />
          </div>
        </a>
        <a rel="noreferrer" target="_blank" href="https://discord.gg/pRJUfz2zMP">
          <div className={styles.icon}>
            <img className={styles.link} src={Discord} title="Our Discord" />
          </div>
        </a>
      </div>
    </div>
  }
}