import classNames from 'classnames';
import * as React from 'react';
import { Headline } from './headline';

import { Paragraph } from '../../components/paragraph';
import viewStyles from '../view.module.scss';
import styles from './index.module.scss';
import firebase from 'firebase';
import { Link } from 'react-router-dom';

import CarDriver from "../../assets/grandprix/car_driver.webp";

export default class GrandprixProjectView extends React.Component {
  componentDidMount(): void {
    firebase.analytics().logEvent('page_runwayproject');
  }

  render(): JSX.Element {
    return <div className={viewStyles.view}>
      <Headline/>
      <Paragraph white>
        <Link to="/grandprix/scoreboard?mapId=eagleone.gp_neotokyo">
          <div className={styles.scoreboardlink}>
            Map Scoreboard
          </div>
        </Link>
      </Paragraph>
      <div className={styles.preview}>
        <div className={styles.videoWrapper}>
          <iframe width="648" height="512" src="https://www.youtube.com/embed/Xa3UUM2qZbw" title="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      <Paragraph white> 
        <div className={styles.intro}>
          <img className={styles.cardriver} src={CarDriver}></img>
          "Such a fun game!" - My Mom
        </div>
      </Paragraph>
    </div>
  }
}
