import classnames from 'classnames';
import * as React from 'react';
import styles from './paragraph.module.scss';

type ParagraphProps = {
  className?: string;
  white?: boolean;
} 

export class Paragraph extends React.Component<ParagraphProps> {
  render(): JSX.Element {
    const classNames = classnames(this.props.className ?? "", { 
      [styles.paragraph]: true, 
      [styles.white]: this.props.white 
    });

    return <div className={classNames}>
      <div className={styles.contents}>
        {this.props.children}
      </div>
    </div>;
  }
}