import * as React from 'react';
import styles from './footer.module.scss';
import skewBackground from "../assets/footer.svg"

import Twitter from "../assets/logos/twitter_white.svg";
import Discord from "../assets/logos/discord_white.svg";

export class Footer extends React.Component {
  render(): JSX.Element {

    return <div className={styles.footer}>
      <img src={skewBackground} className={styles.background}></img>
      <div className={styles.contents}>
        <div className={styles.headline}>
          <div className={styles.title}>EAGLE ONE</div>
          <div className={styles.subtitle}>We love to make games.</div>
          <div className={styles.social}>
            <a rel="noreferrer" target="_blank" href="https://twitter.com/EagleOneDevs">
              <div className={styles.icon}>
                <img className={styles.link} src={Twitter} title="Our Twitter" />
              </div>
            </a>
            <a rel="noreferrer" target="_blank" href="https://discord.gg/pRJUfz2zMP">
              <div className={styles.icon}>
                <img className={styles.link} src={Discord} title="Our Discord" />
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>;
  }
}