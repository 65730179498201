//https://us-central1-eagle-one-web.cloudfunctions.net/speeddial-scoreboard

import * as React from 'react';
import firebase from 'firebase/app';

import styles from './index.module.scss';
import bent from 'bent';
import viewStyles from '../view.module.scss';

import policeTape from "../../assets/police_tape.png"
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface Player {
  name: string;
  score: number;
}

interface ScoreboardState {
  players: Player[]
}

export default class SpeeddialScoreboardView extends React.Component<unknown, ScoreboardState> {
  onMouseMove_ : (e: MouseEvent) => void;
  containerRef_: React.RefObject<HTMLDivElement>;
  
  constructor(props: unknown) {
    super(props);
    
    this.state = {players: [] };
    this.containerRef_ = React.createRef();
  }
  

  componentDidMount(): void {
    firebase.analytics().logEvent('page_speeddial_scoreboard');

    const getJSON = bent('json');
    getJSON('https://us-central1-eagle-one-web.cloudfunctions.net/speeddial-scoreboard').then((players: Player[]) => {
      this.setState({players})
    });

    this.onMouseMove_ = (e: MouseEvent) => {
      const x = e.clientX;
      const y = e.clientY;
      const transformX = (window.innerWidth / 2 - x)/500;

      if (window.innerWidth < 1000) {
        this.containerRef_.current.style.transform = "unset";
        return;
      }

      this.containerRef_.current.style.transform = `perspective(100vw) rotateY(${transformX}deg)`;
      (this.containerRef_.current.getElementsByClassName(styles["header-text"])[0] as HTMLDivElement).style.transform = `perspective(100vw) rotateY(${-transformX*5}deg) rotateZ(${transformX/2}deg)`;
      (this.containerRef_.current.getElementsByClassName(styles.player)[0] as HTMLDivElement).style.transform = `translateY(-192px) perspective(100vw) rotateY(${-transformX*5}deg) rotateZ(${transformX/2}deg)`;
    };
    window.addEventListener('mousemove', this.onMouseMove_)
  }


  componentWillUnmount(): void {
    window.removeEventListener('mousemove', this.onMouseMove_)
  }

  render(): JSX.Element {
    return <div className={classNames(viewStyles.view, styles.view)}>

      <div className={styles.scoreboard}>
        <div ref={this.containerRef_} className={styles.container}>
          <Link to="/projects/speeddial">
              <div className={styles["most-wanted"]}>
                <div className={classNames(styles.link, styles["most-wanted-button"])}>RETURN TO GAME</div>
              </div>
          </Link>
          <div className={styles["header-title"]}>
            SPEED DIAL&#39;s
          </div>
          <div className={styles.header}>
            <div className={styles["header-text"]}>MOST WANTED</div>
          </div>
          <img className={styles["police-tape"]} src={policeTape}></img>
          {this.state.players.map(this.playerTemplate)}
        </div>
      </div>
    </div>;
  }

  playerTemplate(player: Player, index: number): JSX.Element {
    const classes = classNames({
      [styles.player]: true,
      [styles.champ]: index == 0,
      [styles.top]: index > 0 && index < 5
    });

    return <div key={index} className={classes}>
      <div className={styles.name}>
        {player.name}
      </div>
      <div className={styles.score}>
        {player.score}
      </div>
    </div>
  }
}

