import * as React from "react";
import { Link } from "react-router-dom";
import runwayLog from "../../assets/runway/runway.webp";

import styles from "./headline.module.scss";

export const Headline: () => JSX.Element = () => {
  return (
    <div className={styles.headline}>
      <div className={styles.group}>
      <img src={runwayLog} className={styles.title}></img>
      <a rel="noreferrer" target="_blank" href="https://steamcommunity.com/sharedfiles/filedetails/?id=2502791345">
        <div className={styles["demo-link"]}>
          PLAY THE DEMO
        </div>
      </a>
      </div>
    </div>
  );
}