import classNames from 'classnames';
import * as React from 'react';
import { Headline } from './headline';

import runwayImage from "../../assets/runway/preview_02.webp";
import scanner from "../../assets/scanner.gif";
import ostImage from "../../assets/runway/ost.webp";

import { Paragraph } from '../../components/paragraph';
import { Gallery } from '../../components/gallery';
import viewStyles from '../view.module.scss';
import styles from './index.module.scss';
import galleryResource from "../../assets/runway/gallery/manifest";
import firebase from 'firebase';

export default class RunwayProjectView extends React.Component {
  componentDidMount(): void {
    firebase.analytics().logEvent('page_runwayproject');
  }

  render(): JSX.Element {
    return <div className={viewStyles.view}>
      <Headline/>
      <Paragraph white>
        Your time has finally come...<br/><br/>
        <b className={styles.noun}>&emsp;&emsp;Agent Alyx Bond.</b><br/><br/>
        Play our first chapter &quot;Complex&quot; now! Immerse yourself in our alternate Half-Life universe as you travel deep into Soviet Combine territory.
      </Paragraph>
      <img className={styles.scanner} src={scanner}></img>
      <img className={classNames(styles.scanner, styles.rightBound)} src={scanner}></img>
      <div className={styles.preview}>
        <img src={runwayImage}></img>
      </div>
      <Paragraph white>
        <b>Alyx Bond: Runway </b> is a love letter to Half Life: Alyx—handcrafted by some of the most experienced Source 2 developers in the community.
        Featuring custom assets, a fully composed original sound track, voice actors, and animations (with motion capture scenes). Complete with new gameplay mechanics, weapons, and enemies to fight!
        <br/><br/>
        The demo is just a taste of the 8+ hour campaign we're working on!
      </Paragraph>
      <div className={styles.title}>Content Gallery</div>
      <Gallery resources={galleryResource}></Gallery>
      <div className={classNames(styles.preview, styles.thumbnail)}>
        <div className={styles.title}>Gameplay Footage</div>
          <div className={styles.videoWrapper}>
            <iframe width="648" height="512" src="https://www.youtube.com/embed/VUxlO8kxw5o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        <div className={styles.title}>Original Soundtrack</div>
        <a rel="noreferrer" target="_blank" href="https://open.spotify.com/album/4hy64b5a3bVYsaBk6OJi0L?si=qI9TfYtjS4aBtInpqs2g_A&dl_branch=1">
          <img src={ostImage}></img>
        </a>
      </div>
    </div>
  }
}
