import * as React from "react";
import { Link } from "react-router-dom";
import runwayLog from "../../assets/grandprix/logo.svg";

import styles from "./headline.module.scss";

export const Headline: () => JSX.Element = () => {
  return (
    <div className={styles.headline}>
      <div className={styles.group}>
        <img src={runwayLog} className={styles.title}></img>
      </div>
    </div>
  );
}