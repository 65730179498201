import bent from 'bent';
import * as React from 'react';
import classNames from 'classnames';

import viewStyles from './view.module.scss';
import styles from "./team.module.scss"

import ArtstationLogo from "../assets/logos/artstation.svg";
import GithubLogo from "../assets/logos/github.svg";
import InstagramLogo from "../assets/logos/instagram.svg";
import SpotifyLogo from "../assets/logos/spotify.svg";
import SteamLogo from "../assets/logos/steam.svg";
import TwitterLogo from "../assets/logos/twitter_black.svg";
import WebsiteLogo from "../assets/logos/web.svg";
import YoutubeLogo from "../assets/logos/youtube.svg";
import ItchLogo from "../assets/logos/itch.svg";
import TwitchLogo from "../assets/logos/twitch.svg";
import IMDBLogo from "../assets/logos/imdb.svg";
import Cross from "../assets/cross.svg";
import firebase from 'firebase/app';

interface Member {
  info?: { [key: string]: string | undefined },
  name: string;
  role: role;
  bio?: string;
}

interface TeamViewState {
  categories: Record<categories, Member[]> | Record<string, never>;
  selectedMember?: Member;
}

const Icons: { [key: string]: string } = {
  github: GithubLogo as string,
  steam: SteamLogo as string,
  twitter: TwitterLogo as string,
  website: WebsiteLogo as string,
  youtube: YoutubeLogo as string,
  artstation: ArtstationLogo as string,
  instagram: InstagramLogo as string,
  spotify: SpotifyLogo as string,
  itch: ItchLogo as string,
  twitch: TwitchLogo as string,
  imdb: IMDBLogo as string,
}

type infoType = "github" | "steam" | "twitter" | "website" | "youtube" | "artstation" | "spotify" | "itch" | "twitch" | "imdb";
type role = "Project Lead" | "Source 2 Producer" | "Promotional Artist" | "Website" | "SFM Artist" |
  "Programmer" | "Sound Engineer" | "Editor" | "2D Artist" | "3D Artist" | "Level Designer" |
  "Animator" | "Particle Artist" | "Voice Actor" | "Asset Curator" | "Lead Level Designer" | "Team Lead";
type categories = "Producer" | "Game Designers" | "Animators" | "2D Artists" | "3D Artists" | "Audio Engineers" | "Programmers" | "Asset Curators";

export default class TeamView extends React.Component<unknown, TeamViewState> {

  constructor(props: unknown) {
    super(props);

    this.state = { categories: {} };
  }

  handleOpenModal(selectedMember: Member): void {
    this.setState({ selectedMember });
  }

  handleCloseModal(): void {
    this.setState({ selectedMember: undefined });
  }

  componentDidMount(): void {

    firebase.analytics().logEvent('page_team');

    Object.keys(Icons).forEach((iconName) => {
      const picture = Icons[iconName];

      const img = new Image();
      img.src = picture;
    });

    const getJSON = bent('json');
    getJSON('https://eagleone.dev/members.json').then((members: Member[]) => {
      const categories: Record<categories, Member[]> = {
        "Programmers": [],
        "Game Designers": [],
        "2D Artists": [],
        "3D Artists": [],
        "Animators": [],
        "Audio Engineers": [],
        "Producer": [],
        "Asset Curators": [],
      };

      for (let index = 0; index < members.length; index++) {
        const member = members[index];

        switch (member.role) {
          case "Team Lead":
            categories['Producer'].push(member);
            break;
          case "Animator":
            categories['Animators'].push(member);
            break;
          case "Editor":
          case "Promotional Artist":
          case "2D Artist":
            categories['2D Artists'].push(member);
            break;
          case "SFM Artist":
          case "3D Artist":
          case "Particle Artist":
            categories['3D Artists'].push(member);
            break;
          case "Voice Actor":
          case "Sound Engineer":
            categories['Audio Engineers'].push(member);
            break;
          case "Level Designer":
          case "Lead Level Designer":
            categories['Game Designers'].push(member);
            break;
          case "Programmer":
          case "Website":
            categories["Programmers"].push(member);
            break;
          case "Asset Curator":
            categories["Asset Curators"].push(member);
            break;
          default:
            console.log(Error("Member had unknown role: " + member.role));
        }
      }

      this.setState({ categories })
    });
  }

  render(): JSX.Element {

    const categoryNames = Object.keys(this.state.categories) as categories[];
    const categories = categoryNames.sort(categorySort).map((name, i) => this.categoryTemplate(name, this.state.categories[name], i));

    return <div className={viewStyles.view}>
      <div className={classNames(styles.content, viewStyles.content)}>
        {categories}
      </div>
      {this.modalTemplate()}
    </div>;
  }

  modalTemplate(): JSX.Element {
    const member = this.state.selectedMember;
    if (!member) return <div></div>;


    const infoKeys = Object.keys(member.info ?? {}).sort();
    const infoLinks = infoKeys.map((key: string) => {
      return <a className={styles.socialLink} key={`${member}-${key}`} rel="noreferrer" target="_blank" href={member.info[key]}>
        <img src={Icons[(key as infoType)] ?? Icons["website"]}></img>
      </a>;
    })

    let bio = undefined;
    if (member.bio) {
      bio = <div className={styles.bio}>
        {member.bio}
      </div>;
    }

    const closeMenu = () => { this.setState({ selectedMember: undefined }) };

    return <div onClick={closeMenu} className={classNames({ [styles.show]: !!member }, styles.modal)}>
      <div onClick={(e) => { e.stopPropagation() }} className={styles.container}>
        <div className={styles["close-button"]} onClick={closeMenu}><img src={Cross}></img></div>
        <div className={styles.membername}>{this.state.selectedMember.name}<br /><div className={styles.memberrole}>{this.state.selectedMember.role}</div></div>
        {bio}
        {(infoLinks.length > 0) ?
          <div className={styles.infoGroup}>
            {infoLinks}
          </div>
          : undefined
        }
      </div>
    </div>
  }

  categoryTemplate(name: categories, members: Member[], index: number): JSX.Element {

    const memberElements: JSX.Element[] = members.sort().map((member, i) => {
      return <div key={name + index + member.name + i} className={styles.member}>
        <button onClick={() => this.handleOpenModal(member)} className={styles.membertitle}>
          {member.name}
        </button>
      </div>;
    })

    return <div className={styles.category} key={name + index}>
      <div className={styles.title}>{name}</div>
      {memberElements}
    </div>
  }
}

function categorySort(a: categories, b: categories): number {
  return categoryGetSortValue(b) - categoryGetSortValue(a);
}

function categoryGetSortValue(category: categories): number {
  let value = 0;

  switch (category) {
    case "Asset Curators":
      value = 5;
      break;
    case "Animators":
      value = 6;
      break;
    case "Audio Engineers":
      value = 7;
      break;
    case "3D Artists":
      value = 8;
      break;
    case "2D Artists":
      value = 9;
      break;
    case "Game Designers":
      value = 10;
      break;
    case "Programmers":
      value = 11;
      break;
    case "Producer":
      value = 12;
      break;
  }

  return value;
}