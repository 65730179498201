import AssetOne from "./runway01.webp";
import AssetTwo from "./runway02.webp";
import AssetThree from "./runway03.webp";
import AssetFour from "./runway04.webp";
import AssetFive from "./runway05.webp";
import AssetSix from "./runway06.webp";
import AssetEight from "./runway07.webp";

export default [
  AssetOne,
  AssetTwo,
  AssetThree,
  AssetFour,
  AssetFive,
  AssetSix,
  AssetEight
]