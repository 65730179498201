import * as React from 'react';

import styles from './FrontPage.module.scss';
import { VideoBackground } from './video_background';
import viewStyles from '../view.module.scss';
import classNames from 'classnames';
import Projects from '../../components/projects';
import firebase from 'firebase';
import car01 from "../../assets/frontpage/car_01.webp";
import car02 from "../../assets/frontpage/car_02.webp";
import gamejam from '../../assets/banner.webp';


export default class FrontPage extends React.Component {
  componentDidMount(): void {
    firebase.analytics().logEvent('page_landing');
  }

  render(): JSX.Element {
    
    return (
      <div className={viewStyles.view}>
        <VideoBackground className={styles["video-background"]} />
        <div className="container">
          <div className="row">
            <div className={classNames("col", styles.centered)}>
              <div style={{backgroundColor: "#2a2a2a", width: "200%", justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                <br/>
                <a className={styles["jambox-link"]} href="https://jambox.dev/">
                  <img src={gamejam} className={styles.jambox}/>
                </a>
                <div className={styles["jambox-info"]}>
                  We are hosting s&#38;box game jam! Click below to learn more
                </div>
                <br/><br/>
                <a href="https://jambox.dev/" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-lg px-4">Jambox Website</a>
                <br/><br/>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              .<br/>
              .<br/>
              .<br/>
              <br/>
              <br/>
              <br/>
              <br/>
              {/* <div className={styles.sampler}>
                <img className={styles.sample} src={car01}></img>
                <img className={styles.sample} src={car02}></img>
              </div> */}
              <div className={styles.headline}>
                We make games for Half-Life: Alyx and s&#38;box.
              </div>
              <Projects />
            </div>
          </div>
        </div>
    </div>
    );
  }
}
