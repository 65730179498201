import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './projects.module.scss';

import RunwayThumbnail from "../assets/runway_thumbnail.webp";
import SpeedDialThumbnail from "../assets/speeddial_thumbnail.webp";
import GrandprixThumbnail from "../assets/grandprix/thumbnail.webp";

interface Project {
  thumbnail: string,
  title: string;
  status: string;
  path: string;
  backgroundColor: string;
}

const ProjectsData: Project[] = [
  {
    thumbnail: RunwayThumbnail,
    title: "Alyx Bond: Runway",
    status: "Work in progress",
    path: "/projects/runway",
    backgroundColor: "rgb(179 79 68)",
  },
  {
    thumbnail: SpeedDialThumbnail,
    title: "Speed-Dial",
    status: "Beta",
    path: "/projects/speeddial",
    backgroundColor: "rgb(45 45 58)",
  },
  {
    thumbnail: GrandprixThumbnail,
    title: "Gr&prix",
    status: "Beta",
    path: "/projects/grandprix",
    backgroundColor: "#5b9eeb",
  },
]

export default class Projects extends React.Component {
  render(): JSX.Element {
    return <div>
      <div className={styles.projects}>
        {ProjectsData.map((p) => this.projectTemplate(p))}
      </div>
    </div>
  }

  projectTemplate(project: Project): JSX.Element {
    return <Link to={project.path}>
      <div key={project.title} className={styles.project} style={{backgroundColor: project.backgroundColor}}>
        <img src={project.thumbnail}></img>
        <div className={styles.title}>{project.title}</div>
        <div className={styles.status}>{project.status}</div>
      </div>
    </Link>
  }
}