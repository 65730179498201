import Asset1 from "./speed-dial01.webp";
import Asset2 from "./speed-dial02.webp";
import Asset3 from "./speed-dial03.webp";
import Asset4 from "./speed-dial04.webp";
import Asset5 from "./speed-dial05.webp";
import Asset6 from "./speed-dial06.webp";
import Asset7 from "./speed-dial07.webp";
import Asset8 from "./speed-dial08.webp";
import Asset9 from "./speed-dial09.webp";
import Asset10 from "./speed-dial10.webp";
import Asset11 from "./speed-dial11.webp";
import Asset12 from "./speed-dial12.webp";
import Asset13 from "./speed-dial13.webp";
import Asset14 from "./speed-dial14.webp";
import Asset15 from "./speed-dial15.webp";
import Asset16 from "./speed-dial16.webp";


export default [
  Asset1,
  Asset2,
  Asset3,
  Asset4,
  Asset5,
  Asset6,
  Asset7,
  Asset8,
  Asset9,
  Asset10,
  Asset11,
  Asset12,
  Asset13,
  Asset14,
  Asset15,
  Asset16
]