import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation
} from 'react-router-dom';

import { AppNavigation  } from "./components/app_navigation";
import { Footer } from './components/footer';
import AboutView from './views/about';
import ProjectsView from "./views/projects";
import FrontPage from './views/FrontPage/FrontPage';
import TeamView from "./views/team";

import './index.scss';
import RunwayProjectView from './views/runway';
import ScrollToTop from "./components/scrolltotop";
import SpeeddialProjectView from "./views/speeddial";

import firebase from 'firebase/app';
import SpeeddialScoreboardView from "./views/speeddial_scoreboard";
import GrandprixScoreboardView from "./views/grandprix_scoreboard";
import GrandprixProjectView from "./views/grandprix";

const firebaseConfig = {
  apiKey: "AIzaSyBia0MfiWyckgnFM01h1YtaNM-FmDtAtnI",
  authDomain: "eagle-one-web.firebaseapp.com",
  projectId: "eagle-one-web",
  storageBucket: "eagle-one-web.appspot.com",
  messagingSenderId: "314586795363",
  appId: "1:314586795363:web:af193edb306cdb6178d756",
  measurementId: "G-LYM4YM8EKM"
};

firebase.initializeApp(firebaseConfig);

/**
 *  The main react component that is render into the #app HTML element.
 */
class App extends React.Component {

    render(): JSX.Element {
      return (
        <Router>
          <nav>
            <AppNavigation />
          </nav>
          <ScrollToTop />
          <Switch>
            <Route path="/about">
              <AboutView/>
            </Route>
            <Route path="/projects/runway">
              <RunwayProjectView />
            </Route>
            <Route path="/projects/speeddial">
              <SpeeddialProjectView />
            </Route>
            <Route path="/projects/grandprix">
              <GrandprixProjectView />
            </Route>
            <Route path="/speeddial/scoreboard">
              <SpeeddialScoreboardView />
            </Route>
            <Route path="/grandprix/scoreboard">
              <ScoreboardQueryWrapper />
            </Route>
            <Route path="/projects">
              <ProjectsView />
            </Route>
            <Route path="/team">
              <TeamView/>
            </Route>
            <FrontPage />
          </Switch>
          <Footer />
        </Router>
      );
    }
  }

ReactDOM.render(
    <App />,
    document.getElementById("app")
);

function ScoreboardQueryWrapper() {
  const query = new URLSearchParams(useLocation().search);
  const mapId = query.get("mapId");

  return  <GrandprixScoreboardView mapId={mapId} />;
}