import classNames from 'classnames';
import * as React from 'react';
import styles from './gallery.module.scss';

import Arrow from "../assets/arrow.svg";

interface GalleryProps {
  resources: string[]
}

interface GalleryState {
  selectedResource?: string;
}

export class Gallery extends React.Component<GalleryProps, GalleryState> {
  constructor(props: GalleryProps, state: GalleryState) {
    super(props, state);

    this.state = {};
  }


  render(): JSX.Element {
    return <div className={styles.gallery}>
      {this.props.resources.map((r) => this.galleryItemTemplate(r))}
      {this.galleryPopupTemplate()}
    </div>;
  }

  galleryItemTemplate(resource: string): JSX.Element {
    return <img className={styles.galleryItem} src={resource} key={'Asset:' + resource.toString()}
    onClick={() => {
      this.setState({selectedResource: resource})
    }}/>;
  }

  galleryPopupTemplate(): JSX.Element {

      const resource = this.state.selectedResource;
      if (!resource) return <div></div>;

      const closeMenu = () => {this.setState({selectedResource: undefined})};
  
      return <div onClick={closeMenu} className={classNames({[styles.show]: !!resource}, styles.modal)}>
        <div onClick={(e) => {
            this.moveToPreviousImage(resource); 
            e.stopPropagation();
          }} className={styles.arrowButton}>
          <img src={Arrow}></img>
        </div>
        <div onClick={(e) => {e.stopPropagation()}} className={styles.container}>
          <img className={styles["gallery-preview"]} src={this.state.selectedResource} />
        </div>
        <div onClick={(e) => {
            this.moveToNextImage(resource); 
            e.stopPropagation();
          }} className={styles.arrowButton}>
          <img src={Arrow}></img>
        </div>
      </div>
  }

  moveToNextImage(resource: string) {
    const { resources } = this.props;
    const index = (resources.indexOf(resource) + 1) % resources.length;

    this.setState({selectedResource: resources[index]});
  }

  moveToPreviousImage(resource: string) {
    const { resources } = this.props;
    let index = resources.indexOf(resource) - 1;

    if (index < 0) {
      index = resources.length - 1;
    }

    this.setState({selectedResource: resources[index]});
  }
}